import classNames from 'classnames';

interface DividerProps {
  className?: string;
  variant?: 'thin' | 'thick';
  orientation?: 'horizontal' | 'vertical';
  opacity?: boolean;
}

export const Divider = ({
  className,
  variant = 'thin',
  orientation = 'horizontal',
  opacity = true,
}: DividerProps) => (
  <div
    className={classNames(
      {
        'bg-suma-blue-dark': variant === 'thin',
        'bg-suma-blue shadow-inner': variant === 'thick',
        'w-full h-px': variant === 'thin' && orientation === 'horizontal',
        'h-full w-px': variant === 'thin' && orientation === 'vertical',
        'w-full h-2 my-4 md:my-8': variant === 'thick' && orientation === 'horizontal',
        'h-full w-2 mx-4 md:mx-8': variant === 'thick' && orientation === 'vertical',
        'opacity-20': variant === 'thin' && opacity,
      },
      className
    )}
  />
);
