import { TextInputField, TextInputProps } from './TextInput';

import { MaskedRange } from 'imask';
import React from 'react';
import { TextInput } from '@/components/molecules/TextInput';

export interface ExpirationDateInputProps extends TextInputProps {}

export const ExpirationDateInput: React.FC<ExpirationDateInputProps> = (
  props: ExpirationDateInputProps
) => {
  const C = props.name ? TextInputField : TextInput;
  return (
    // @ts-ignore Dynamic component, no problem
    <C
      inputMode="decimal"
      placeholder="MM/YY"
      options={{
        mask: 'MM/YY',
        blocks: {
          YY: {
            mask: '00',
          },

          MM: {
            mask: MaskedRange,
            from: 1,
            to: 12,
            autofix: 'pad',
          },
        },
        lazy: true,
        unmask: false,
      }}
      {...props}
    />
  );
};
