export const DEFAULT_SPRING_CONFIG = {
  type: 'spring',
  stiffness: 300,
  damping: 25,
};

export const AGGRESSIVE_SPRING_CONFIG = {
  type: 'spring',
  stiffness: 500,
  damping: 5,
};
