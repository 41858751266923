import { TextInput, TextInputProps } from './TextInput';
import { useEffect, useState } from 'react';

import { CalendarModal } from '../Calendar/Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMask } from 'react-imask';
import { TextInputField } from '.';
import classNames from 'classnames';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { formatDate } from '@/utils/format';
import Typography from '@/components/atoms/Typography';

export interface DateInputProps extends Omit<TextInputProps, 'value'> {
  value?: Date;
}

export interface DateInputProps_Revision08102022 {
  value?: Date;
  onChange: (date: Date) => any;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  defaultValue?: Date;
}

export const DateInput = (props: DateInputProps) => {
  const C = props.name ? TextInputField : TextInput;

  return (
    <div className="relative">
      {/* @ts-ignore Dynamic component, no problem */}
      <C
        iconLeft={faCalendar}
        inputMode="numeric"
        options={{
          mask: Date,
          unmask: 'typed',
          pattern: 'm{/}`d{/}`Y',
          blocks: {
            d: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 31,
              maxLength: 2,
            },
            m: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
              maxLength: 2,
            },
            Y: {
              mask: IMask.MaskedRange,
              from: 1900,
              to: 9999,
            },
          },
          format: function (date?: Date) {
            if (!date) return '';
            try {
              let day = `${date.getDate()}`;
              let month = `${date.getMonth() + 1}`;
              const year = `${date.getFullYear()}`;

              if (+day < 10) day = '0' + day;
              if (+month < 10) month = '0' + month;

              return [month, day, year].join('/');
            } catch (error) {
              return date;
            }
          },
          parse: function (dateStr: string) {
            return new Date(dateStr);
          },
          autofix: true,
          lazy: false,
          overwrite: true,
        }}
        {...props}
      />
    </div>
  );
};

export const DateInput_Revision08102022 = ({
  value,
  onChange,
  disabled,
  minDate,
  maxDate,
  defaultValue,
}: DateInputProps_Revision08102022) => {
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <span className="flex items-center gap-2 cursor-pointer flex-grow" onClick={() => setCalendarOpen(true)}>
      <FontAwesomeIcon icon={faCalendar} className="text-sm md:text-xl" />
      <Typography
        className={classNames('text-suma font-bold', {
          'text-gray-dark': disabled,
        })}
      >
        {value ? formatDate(value) : 'Choose Date'}
      </Typography>

      <CalendarModal
        minDate={minDate}
        maxDate={maxDate}
        isOpen={isCalendarOpen}
        onClose={() => setCalendarOpen(false)}
        onCancel={() => setCalendarOpen(false)}
        value={selectedValue}
        onClickDay={(date) => setSelectedValue(date)}
        onConfirm={(date) => {
          setCalendarOpen(false);
          onChange(date);
        }}
        defaultValue={selectedValue}
      />
    </span>
  );
};
