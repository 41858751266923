import { Dialog } from '@headlessui/react';
import Divider from '../Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VerticalLayout from '@/components/layouts/VerticalLayout';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import classNames from 'classnames';

export interface ModalProps {
  isOpen: boolean;
  onClose: AnyFunction;
  closeWithBackground?: boolean;
  opacity?: 'default' | 'medium' | 'high';
}

export interface DefaultModalProps {
  isOpen: boolean;
  onClose: AnyFunction;
  includeCloseButton?: boolean;
  isCloseable?: boolean;
  contentBeneath?: React.ReactNode;
  closeWithBackground?: boolean;
  withoutBackgroundColor?: boolean;
  className?: string;
  iconColor?: string;
  opacity?: 'default' | 'medium' | 'high';
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  closeWithBackground = false,
  opacity = 'default',
}) => {
  return (
    <Dialog
      className="fixed inset-0 z-[10000] h-screen w-screen overflow-y-auto"
      onClose={onClose}
      open={isOpen}
    >
      <div className="min-h-screen h-screen px-4 text-center flex flex-col">
        <Dialog.Overlay
          onClick={(event: any) => {
            event.stopPropagation();
            if (closeWithBackground) onClose();
          }}
          className={classNames(
            'fixed inset-0 transform-gpu bg-black backdrop-blur-sm backdrop-filter',
            {
              'bg-opacity-50': opacity === 'default',
              'bg-opacity-70': opacity === 'medium',
              'bg-opacity-80': opacity === 'high',
            }
          )}
        />

        {/* Centers the sticky modal vertically when the screen height is taller than modal */}
        <div className="flex-grow flex-shrink-0"></div>
        <div className="sticky w-full top-0">{children}</div>
        <div className="flex-grow flex-shrink-0"></div>
      </div>
    </Dialog>
  );
};

export const DefaultModal: React.FC<DefaultModalProps> = ({
  isOpen,
  onClose,
  includeCloseButton = true,
  contentBeneath,
  children,
  closeWithBackground = false,
  withoutBackgroundColor,
  className = 'inline-block w-full max-w-lg transform text-left align-middle transition-all md:p-12 overflow-x-hidden p-8',
  iconColor,
  opacity,
}) => {
  // bg-main
  return (
    <Modal
      opacity={opacity}
      isOpen={isOpen}
      onClose={onClose}
      closeWithBackground={closeWithBackground}
    >
      <div
        className={classNames(className, {
          'bg-main shadow-xl': !withoutBackgroundColor,
          'bg-transparent': withoutBackgroundColor,
        })}
      >
        <VerticalLayout
          className={classNames({
            'bg-transparent': withoutBackgroundColor,
          })}
        >
          {children}
          {contentBeneath && <Divider />}
          {contentBeneath}
        </VerticalLayout>
        {includeCloseButton && (
          <FontAwesomeIcon
            color={iconColor}
            icon={faTimes}
            onClick={(event) => {
              event.stopPropagation();
              onClose();
            }}
            className="fa-lg absolute top-0 right-0 cursor-pointer p-4"
          />
        )}
      </div>
    </Modal>
  );
};

export default Modal;
