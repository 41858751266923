import React, { useState } from 'react';
import ReactCalendar, { CalendarProps } from 'react-calendar';

import Button from '@/components/atoms/Button';
import Modal from '@/components/atoms/Modal';
import VerticalLayout from '@/components/layouts/VerticalLayout';
import useAnalyticsTrack from '@/hooks/useAnalyticsTrack';
import { useCallback } from 'react';

export interface CalendarConfirmationProps extends CalendarProps {
  isSubmitting?: boolean;
  confirmText?: string;
  cancelText?: string;
  onCancel?: AnyFunction;
  onConfirm: (date: Date) => void;
}

export interface CalendarModalProps extends CalendarConfirmationProps {
  isOpen: boolean;
  onClose: AnyFunction;
}

export const CalendarModal: React.FC<CalendarModalProps> = ({
  isOpen,
  onCancel,
  onClose,
  defaultValue,
  ...calendarConfirmationProps
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(defaultValue as Date);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeWithBackground={true}>
      <CalendarConfirmation
        onCancel={onClose}
        defaultValue={selectedDate}
        onChange={(date: Date) => setSelectedDate(date)}
        {...calendarConfirmationProps}
      />
    </Modal>
  );
};

export const CalendarConfirmation: React.FC<CalendarConfirmationProps> = ({
  onConfirm,
  onCancel,
  isSubmitting,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  ...calendarProps
}) => {
  const [selectedDate, setSelectedDate] = useState(calendarProps.defaultValue);
  const { track, trackButtonClick } = useAnalyticsTrack();

  const onClickDay = useCallback(
    (date: Date) => {
      track('Day Clicked', {
        date,
      });
    },
    [track]
  );

  return (
    <VerticalLayout>
      <div className="-m-8 pt-6 md:-m-12">
        <Calendar
          {...calendarProps}
          onChange={(date: Date) => {
            setSelectedDate(date);
            onClickDay(date);
          }}
        />

        <div className="grid grid-cols-12 p-2 gap-2">
          <Button
            id="calendar-cancel-button"
            variant="transparent"
            className="col-span-6 md:col-span-4 mr-2"
            onClick={(event) => {
              event.stopPropagation();
              trackButtonClick(event);
              onCancel?.(event);
            }}
            disabled={isSubmitting}
          >
            {cancelText}
          </Button>
          <Button
            id="calendar-confirm-button"
            variant="primary"
            onClick={(event) => {
              event.stopPropagation();
              trackButtonClick(event);
              onConfirm(selectedDate as Date);
            }}
            className="px-2 col-span-6 md:col-span-8"
            loading={isSubmitting}
            disabled={!selectedDate}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </VerticalLayout>
  );
};

const Calendar: React.FC<CalendarProps> = (props) => {
  return <ReactCalendar {...props} />;
};

export default Calendar;
