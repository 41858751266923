import { TextInput, TextInputField } from '.';

import React from 'react';
import { TextInputProps } from './TextInput';

export interface PasswordInputProps extends TextInputProps {}

export const PasswordInput: React.FC<PasswordInputProps> = ({ className, ...props }) => {
  const C = props.name ? TextInputField : TextInput;
  // @ts-ignore Dynamic component, no problem
  return <C className={className} type="password" placeholder="Password" {...props} />;
};
